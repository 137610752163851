.advantages:nth-child(4) {
  margin-top: 35px;
}

.advantages:nth-child(5) {
  margin-top: 35px;
}

.advantages:nth-child(6) {
  margin-top: 35px;
  margin-bottom: 20px;
}

.advantages:nth-child(3) {
  margin-top: -15px;
}

.header img {
  margin-right: 30px;
  height: 97px;
  width: 97px;
}
.button {
    position: relative;
    padding: 20px 20px 20px 30px;
    color: #fff;
    font-size: 20px;
    font-family: Muli, Helvetica Neue, Arial, sans-serif;
    text-transform: uppercase;
    letter-spacing: 10px;
    transition: 0.5s;
    cursor: pointer;
}

.button:hover {
  background: #0e77ff;
  transition-delay: 1.3s;
  box-shadow: -30px 30px 50px rgba(0, 0, 0, .4);
}

.button:before, .button:after {
    content: '';
    position: absolute;
    width: 2px;
    height: 100%;
    background: #fff;
    pointer-events: none;
    transform-style: linear;
    transition-property: width, opacity, trasnform, box-shadow;
    transition-duration: 0.5s;
}

.button:before {
  top: 0;
  left: 0;
}

.button:after {
  top: 0;
  right: 0;
}

.button:hover:before {
  width: 100%;
  opacity: 0.1; 
  transform: translate(5px, -5px);
  box-shadow: +2px 2px 2px rgba(0, 0, 0, .1);
  transition-delay: 0s, 0.5s, 1s, 1.5s;
}

.button:hover:after {
  width: 100%;
  opacity: 0.1; 
  transform: translate(10px, -10px);
  box-shadow: +2px 2px 2px rgba(0, 0, 0, .1);
  transition-delay: 0s, 0.5s, 1s, 1.5s;
}

.blink {
  -webkit-animation: 1s infinite blink2;
  -moz-animation: 1s infinite blink2;
  animation: 1s infinite blink2;
}


.scrollDown {
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 30px;
  height: 50px;
  margin-left: -15px;
  border: 2px solid #0e77ff;
  border-radius: 50px;
  box-sizing: border-box;
}
.scrollDown::before {
  position: absolute;
  bottom: 30px;
  left: 50%;
  content: '';
  width: 6px;
  height: 6px;
  margin-left: -3px;
  background-color: #0e77ff;
  border-radius: 100%;
  -webkit-animation: 2s infinite scrollDown;
  -moz-animation: 2s infinite scrollDown;
  animation: 2s infinite scrollDown;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 12px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(#fff, #0ea6f7);
  border-radius: 6px;
}

@media (max-width: 480px) {
  .scrollDown {
    display: none;
  }
}

@media (max-width: 767px) {
  .scrollDown {
    display: none;
  }
}

@keyframes scrollDown {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(0, 20px);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink2 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}